import React from "react";
import {Divider, Grid, Header, Icon, Segment} from "semantic-ui-react";
import {FormattedHTMLMessage as MSGS} from "react-intl";

interface IProperties {
    batchDetails : Array<IBatch>
}

interface IBatch {
    reference: string
    paymentDetails: Array<IPayment>
}

interface IPayment {
    amount: number
    currency: string
    creditor: string
    exchangeRate?: number
    charge?: {
        currency: string
        amount: number
        chargeBearer: string
    }
    account?: {
        code: string
        type: string
    }
}

/**
 * Карточка, отображающая информацию о группе платежей, которые проходят авторизацию.
 */
export default class CardBulkPayment extends React.Component<IProperties> {

    render()
    {
        let batches = this.props.batchDetails.map(item => <CardBatch key={item.reference} batch={item}/>)
        return (
            <div>
                <div className={"app-text"}><MSGS id="welcome_page_bulk_payment_consent_first_line"/></div>
                {batches}
            </div>
        )

    }
}

class CardBatch extends React.Component<{batch: IBatch}>
{
    render()
    {
        let payments = this.props.batch.paymentDetails.map((item, count) => <CardBatchPayment key={count} payment={item}/>)
        return (
        <div>
            <div className={"app-text"}>{this.props.batch.reference}</div>
            {payments}
        </div>
        )
    }
}

class CardBatchPayment extends React.Component<{payment: IPayment}> {
    render()
    {
        let from = new Array<JSX.Element>();
        if (this.props.payment.account)
        {
            from.push(
                <Divider vertical key={0}><Icon name='arrow right' /></Divider>,
                <Grid.Column key={1}>
                    {<MSGS id="welcome_page_payment_consent_from"/>}
                    <Header className={"word-wrap"}>
                        {this.props.payment.account.code}
                    </Header>
                </Grid.Column>
            );
        }

        let chargesSegment;
        if (this.props.payment.charge)
        {
            let chargeBearerText;
            if (this.props.payment.charge.chargeBearer ==='DEBT')
            {
                chargeBearerText = 'welcome_page_payment_consent_charge_bearer_our';
            }
            else if (this.props.payment.charge.chargeBearer ==='CRED')
            {
                chargeBearerText = 'welcome_page_payment_consent_charge_bearer_ben';
            }
            else 
            {
                chargeBearerText = 'welcome_page_payment_consent_charge_bearer_sha';
            }

            chargesSegment = 
            <Segment>
                <div className={"app-subtext"}> {<MSGS id="welcome_page_payment_consent_charges"/>}</div>
                <div className={"app-subtext"}>{this.props.payment.charge.amount} {this.props.payment.charge.currency}</div>
                <div className={"app-subtext"}><MSGS id={chargeBearerText}/></div>
            </Segment>
        }

        return (
            <div>
                <Segment>
                <div className={"app-text"}>{this.props.payment.amount} {this.props.payment.currency}</div>
                {chargesSegment}
                <Segment>
                        <Grid columns={2} stackable textAlign='center'>
                            <Grid.Row verticalAlign='middle'>
                                {from}
                                <Grid.Column>
                                    {<MSGS id="welcome_page_payment_consent_to"/>}
                                    <Header>
                                        {this.props.payment.creditor}
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Segment>
            </div>
        )
    }
}