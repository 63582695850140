import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import CommonPage from './view/CommonPage';
import 'semantic-ui-css/semantic.min.css'
import {getVersion, getSystemType, canChangeLocale} from "./Utils";
import {FormattedHTMLMessage} from "react-intl";
import {Dropdown} from "semantic-ui-react";

interface Props {
    changeLanguage: (locale: string) => void;
    currentLocale: string
}

const languageOptions = [
    { key: 'ar', text: 'العربية', value: 'ar' },
    { key: 'en', text: 'English', value: 'en' }
];

class App extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    public handleChange = (e : any, { name, value } : any) => {
        this.props.changeLanguage(value);
    };

    render() {
        return (
            <div>
                {canChangeLocale() ?
                    <Dropdown
                        defaultValue={this.props.currentLocale}
                        button
                        className='icon select-language'
                        floating
                        icon='world'
                        options={languageOptions}
                        closeOnChange
                        onChange={this.handleChange}
                    />
                    :
                    null
                }
                <div className={"app-header"}>
                    <CommonPage/>
                </div>
                <footer>
                    <div className={"footer-logo"}>
                        <img src={process.env.PUBLIC_URL + "/footer/" + getSystemType() + ".png"}
                             className="ui image bank-logo"
                             alt="logo"/>
                    </div>
                    <div className="copyright" title={getVersion()}>
                        <FormattedHTMLMessage id="copyright" values={{year: new Date().getFullYear()}}/>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
