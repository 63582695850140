import React from 'react';
import TextInput from "../components/TextInput";
import {Button} from "semantic-ui-react";
import {FormattedHTMLMessage as MSGS} from "react-intl";

interface Request {
    id: string;
    placeholder: string;
    description: string;
    pattern: string;
    secret: boolean;
}

interface IProperties {
    params: Array<Request>,
    setInputKeys: (inputIds: Array<string>) => void,
}

/**
 * Страница ввода параметров, запрошенных TMSX для авторизации пользователя.
 */
export default class RequestParams extends React.Component <IProperties> {

    render()
    {
        let items = this.props.params.map(item => <TextInput key={item.id} item={item}/>);
        this.props.setInputKeys(this.props.params.map(item => item.id));

        return (
            <div>
                <div className={"app-text"}><MSGS id="authorization_first_line"/></div>
                {items}
                <div className={"page-footer"}>
                    <Button type="submit" primary fluid content={<MSGS id="authorization_button"/>}/>
                </div>
            </div>
        )
    }
}