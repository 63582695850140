import React from "react";
import Hello from "../forms/Hello";
import ErrorMessage from "../forms/ErrorMessage";
import Redirect from "../forms/Redirect";
import AccountSelect from "../forms/AccountSelect";
import RequestParams from "../forms/RequestParams";
import ConsentManage from "../forms/ConsentManage";
import Spinner from "../forms/Spinner";
import Logo from "../components/Logo";
import UserToken from "../forms/UserToken";
import {getContextPath, getSystemType, SystemType, urlParam} from "../Utils";
import {FormattedHTMLMessage as MSGS} from "react-intl";

enum Step {
    START,
    AUTH,
    AUTH_SUCCESS,
    SELECT_ACCOUNT,
    FINAL_AUTH,
    MANAGE_CONSENT,
    USER_TOKEN,
    REDIRECT,
    ERROR,
}

export enum FlowType {
    DEFAULT,
    ICICI
}

export enum Flow {
    ACCESS_CONSENT,
    PAYMENT_CONSENT,
    BULK_CONSENT,
    SPO_CONSENT,
    CONSENT_MANAGEMENT,
    USER_TOKEN
}

export interface AccountSelectItem
{
    name: string
    code: string
    type: string
}

interface IProperties {

}

interface IState {
    systemType: string
    loading: boolean

    flow: Flow
    flowType: FlowType
    step: Step
    previousStep: Step
    stepData?: any
    previousStepData?: any

    redirectUri?: string
    consentId?: string
    paymentConsentId?: string
    bulkConsentId?: string
    spoConsentId?: string
    token?: string
    authCode?: string
}

export default class CommonPage extends React.Component<IProperties, IState> {
    state: IState;
    selectedAccounts?: Set<AccountSelectItem>;
    inputKeys?: Array<string>;
    pispAccount?: string;
    contextPath: string;

    constructor(props: IProperties)
    {
        super(props);

        let consentId: string | undefined = undefined;
        let paymentConsentId: string | undefined = undefined;
        let bulkConsentId: string | undefined = undefined;
        let spoConsentId: string | undefined = undefined;
        let stepData: any = undefined;
        let flow: Flow = Flow.CONSENT_MANAGEMENT;
        let flowType: FlowType = FlowType.DEFAULT;
        let step: Step = Step.START;

        // Параметры из RIB
        let request: string | null = null;
        let encKey: string | null = null;

        let systemType = getSystemType();
        let redirectUri: any = urlParam("redirect_uri");
        this.contextPath = getContextPath();

        // Определяем флоу по урлу
        if (window.location.pathname.startsWith(this.contextPath + "/consentId"))
        {
            let prefix = this.contextPath + "/consentId/";
            consentId = window.location.pathname.substr(window.location.pathname.lastIndexOf(prefix) + prefix.length);
            flow = Flow.ACCESS_CONSENT;
        }
        else if (window.location.pathname.startsWith(this.contextPath + "/paymentConsentId"))
        {
            let prefix = this.contextPath + "/paymentConsentId/";
            paymentConsentId = window.location.pathname.substr(window.location.pathname.lastIndexOf(prefix) + prefix.length);
            flow = Flow.PAYMENT_CONSENT;
        }
        else if (window.location.pathname.startsWith(this.contextPath + "/bulkConsentId"))
        {
            let prefix = this.contextPath + "/bulkConsentId/";
            bulkConsentId = window.location.pathname.substr(window.location.pathname.lastIndexOf(prefix) + prefix.length);
            flow = Flow.BULK_CONSENT;
        }
        else if (window.location.pathname.startsWith(this.contextPath + "/spoConsentId"))
        {
            let prefix = this.contextPath + "/spoConsentId/";
            spoConsentId = window.location.pathname.substr(window.location.pathname.lastIndexOf(prefix) + prefix.length);
            flow = Flow.SPO_CONSENT;
        }
        else if (window.location.pathname.startsWith(this.contextPath + "/user-token"))
        {
            flow = Flow.USER_TOKEN;
        }
        else if (window.location.pathname.startsWith(this.contextPath + "/notFound"))
        {
            stepData = {};
            stepData["code"] = 404;
            step = Step.ERROR;
        }

        if (step == Step.START)
        {
            if (!stepData)
                stepData = {};

            if (consentId)
                stepData["consentId"] = consentId;
            else if (paymentConsentId)
                stepData["paymentConsentId"] = paymentConsentId;
            else if (bulkConsentId)
                stepData["bulkConsentId"] = bulkConsentId;
            else if (spoConsentId)
                stepData["spoConsentId"] = spoConsentId;
        }

        /**
         * В ICICI будет два захода на AuthPage в одном флоу:
         * первый как везде, но после первого запроса на /auth пользователь будет перенаправляться в RIB;
         * второй - редирект назад из RIB и параметром "request" в урле. Флоу будет зависеть от ответа TMSX
         */
        if (systemType === SystemType.ICICI && step !== Step.ERROR)
        {
            flowType = FlowType.ICICI;
            step = Step.AUTH;
            request = urlParam("request");
            encKey = urlParam("encKey");

            // Если параметра нет, то мы должны обратиться к TMSX, после чего редиректнуть в RIB
            if (!request)
            {
                step = Step.START;

                if (redirectUri)
                    stepData["redirectUri"] = redirectUri;

                // Первым делом сразу запрашиваем в TMSX параметры для RIB
                setTimeout(() => this.fetchData("post", stepData), 500);
            }
        }

        // Загружаем состояние из localStorage (например, если нажали F5)
        let loadedState = localStorage.getItem( 'AuthPageState' );
        let loadedTime = localStorage.getItem( 'AuthPageTime' );

        if (loadedTime && loadedState)
        {
            let localStorageTime = new Date(loadedTime).getTime();
            let currentTime = new Date().getTime();
            if (Math.abs(localStorageTime - currentTime) < 300000)
            {
                let jsonState = JSON.parse(loadedState);
                let differentConsent = (paymentConsentId && jsonState["paymentConsentId"] !== paymentConsentId) || (consentId && jsonState["consentId"] !== consentId) ||
                 (bulkConsentId && jsonState["bulkConsentId"] !== bulkConsentId) || (spoConsentId && jsonState["spoConsentId"] !== spoConsentId);
                if (!differentConsent && 
                        (jsonState["flow"] === flow ||
                        (systemType === SystemType.ICICI && request === localStorage.getItem("AuthPageRIB"))))
                {
                    // Если всё ок - считаем, что пользователь авторизован и выполняем первое действие по флоу
                    this.state = jsonState;
                    setTimeout(() => this.fetchData("get"), 500);
                    return;
                }
            }
            // Если пользователь авторизовывался давно (> 5 минут) обнаружили другой Flow или консент отличается - то чистим "сохранение"
            localStorage.clear();
        }

        this.state = {
            systemType: systemType,
            loading: true,

            flow: flow,
            flowType: flowType,
            step: step,
            previousStep: step,
            stepData: stepData,

            redirectUri: redirectUri,
            consentId: consentId,
            paymentConsentId: paymentConsentId,
            bulkConsentId: bulkConsentId,
            spoConsentId: spoConsentId
        };

        // Так как в ICICI мы не можем сразу определить флоу
        // Пересылаем ответ RIB в TMSX первым действием.
        // В ответе TMSX сообщит Flow и другие параметры.
        if (systemType === SystemType.ICICI && request)
        {
            let json : any = {};
            json["rib"] = request;
            json["encKey"] = encKey;

            localStorage.setItem("AuthPageRIB", request);
            setTimeout(() => this.fetchData("post", json), 500);
        }
    }

    localStorageClear = () =>
    {
        // Чистим только ключи с sensitive-датой. Локаль и другое - не чистим.
        localStorage.removeItem('AuthPageState');
        localStorage.removeItem('AuthPageTime');
        localStorage.removeItem('AuthPageRIB');
    };

    initStep = (step: Step, stepData?: any) =>
    {
        this.setState({step: step, stepData: stepData, loading: false});
    };

    showSpinner = () =>
    {
        this.setState({loading: true});
    };

    showError = (error: any) =>
    {
        let curentStep = this.state.step;
        let currentStepData = this.state.stepData;
        this.setState({step: Step.ERROR, previousStep: curentStep, stepData: error, previousStepData: currentStepData, loading: false});
    };

    hideError = () =>
    {
        let previousStep = this.state.previousStep;
        let previousStepData = this.state.previousStepData;
        this.setState({step: previousStep, stepData: previousStepData, loading: false});

        if (previousStep === Step.AUTH_SUCCESS && (this.state.paymentConsentId || this.state.spoConsentId) )
        {
            this.pispAccount = undefined;
            setTimeout(() => this.fetchData("get"), 500);
        }
    };

    /**
     * Обработка отправки формы с клиента.
     */
    handleSubmit = (event: React.FormEvent) =>
    {
        event.preventDefault();
        this.showSpinner();
        const target: any = event.target;

        let json: any = {};

        if (this.state.step === Step.START)
        {
            this.fetchData("post", this.state.stepData);
        }
        else if (this.state.step === Step.AUTH && this.inputKeys)
        {
            this.inputKeys.forEach(key => {
                json[key] = target[key].value;
            });

            if (this.state.consentId)
                json["consentId"] = this.state.consentId;
            else if (this.state.paymentConsentId)
                json["paymentConsentId"] = this.state.paymentConsentId;
            else if (this.state.bulkConsentId)
                json["bulkConsentId"] = this.state.bulkConsentId;
            else if (this.state.spoConsentId)
                json["spoConsentId"] = this.state.spoConsentId;

            this.fetchData("post", json);
        }
        else if (this.state.step === Step.SELECT_ACCOUNT && this.selectedAccounts && this.state.flow === Flow.ACCESS_CONSENT)
        {
            let accounts: any = new Array<string>();
            this.selectedAccounts.forEach(account => {
                accounts.push(account.code);
            });
            json["consentId"] = this.state.consentId;
            json["accounts"] = accounts;

            this.fetchData("post", json);
        }
        else if (this.state.step === Step.SELECT_ACCOUNT && this.selectedAccounts && this.state.flow === Flow.PAYMENT_CONSENT)
        {
            let account = this.selectedAccounts.values().next().value;
            json["paymentConsentId"] = this.state.paymentConsentId;
            json["account"] = account.code;
            json["name"] = account.name;
            json["scheme"] = account.type;

            this.fetchData("post", json);
        }
        else if (this.state.step === Step.SELECT_ACCOUNT && this.selectedAccounts && this.state.flow === Flow.SPO_CONSENT)
        {
            let account = this.selectedAccounts.values().next().value;
            json["spoConsentId"] = this.state.spoConsentId;
            json["account"] = account.code;
            json["name"] = account.name;
            json["scheme"] = account.type;

            this.fetchData("post", json);
        }
        else if (this.state.step === Step.FINAL_AUTH && this.state.flow === Flow.PAYMENT_CONSENT)
        {
            json["account"] = this.pispAccount;
            json["paymentConsentId"] = this.state.paymentConsentId;

            this.fetchData("post", json);
        }
        else if (this.state.step == Step.FINAL_AUTH && this.state.flow === Flow.BULK_CONSENT)
        {
            json["bulkConsentId"] = this.state.bulkConsentId;

            this.fetchData("post", json);
        }
        else if (this.state.step === Step.FINAL_AUTH && this.state.flow === Flow.SPO_CONSENT)
        {
            json["account"] = this.pispAccount;
            json["spoConsentId"] = this.state.spoConsentId;

            this.fetchData("post", json);
        }
        else if (this.state.step === Step.MANAGE_CONSENT || this.state.step === Step.USER_TOKEN)
        {
            // Чистим "сохранение" и обновляем страницу. Типа log out.
            this.localStorageClear();
            // Сброс всех параметров из урла
            window.location.replace(window.location.pathname);
        }
        else
        {
            this.fetchData("post", json);
        }
    };

    /**
     * Отправка запроса к серверу согласно апи.
     */
    fetchData = (method: string, json?: any) =>
    {
        let headers: any = {};
        headers["Content-Type"] = "application/json";
        if (this.state.token)
            headers["Authorization"] = "Bearer " + this.state.token;

        fetch(this.getStepUrl(),
            {
                method: method,
                headers: headers,
                body: JSON.stringify(json)
            })
            .then(response =>
            {
                if (response.ok)
                    return response.json();

                throw new Error();
            })
            .then(response =>
            {
                // Обработка ответа от TMSX
                // Основная задумка - по первому ключу в JSON определяем дальнейшее действие

                // В любом случае сохраняем токен, если он есть.
                if (Object.keys(response).includes("token"))
                {
                    this.setState({token: response.token});
                }

                if (this.state.flow === Flow.USER_TOKEN && Object.keys(response)[0] === "tokenStatus")
                {
                    this.initStep(Step.USER_TOKEN, response); // Если токен есть
                }
                else if (this.state.flow === Flow.USER_TOKEN && Object.keys(response)[0] === "error" && response.error.code === 204)
                {
                    this.initStep(Step.USER_TOKEN, undefined); // Если токена нет
                }
                else if (Object.keys(response)[0] === "accounts")
                {
                    this.initStep(Step.SELECT_ACCOUNT, response.accounts);
                }
                else if (Object.keys(response)[0] === "tpps")
                {
                    this.initStep(Step.MANAGE_CONSENT, response.tpps);
                }
                else if (Object.keys(response)[0] ==="selectedAccount")
                {
                    this.initStep(Step.FINAL_AUTH, response);
                }
                else if (Object.keys(response)[0] === "requests")
                {
                    this.initStep(Step.AUTH, response.requests);

                    if (this.state.flowType === FlowType.ICICI)
                    {
                        // Для ICICI в response должены быть доп.параметры для определения Flow

                        if (Object.keys(response).includes("consentId"))
                        {
                            this.setState({flow: Flow.ACCESS_CONSENT, consentId: response.consentId})
                        }
                        else if (Object.keys(response).includes("paymentConsentId"))
                        {
                            this.setState({flow: Flow.PAYMENT_CONSENT, paymentConsentId: response.paymentConsentId})
                            if (Object.keys(response).includes("debtorAccount"))
                            {
                                this.pispAccount = response.debtorAccount;
                            }
                        }
                        else if (Object.keys(response).includes("bulkConsentId"))
                        {
                            this.setState({flow: Flow.BULK_CONSENT, bulkConsentId: response.bulkConsentId})
                        }
                        else if (Object.keys(response).includes("spoConsentId"))
                        {
                            this.setState({flow: Flow.SPO_CONSENT, spoConsentId: response.spoConsentId})
                            if (Object.keys(response).includes("debtorAccount"))
                            {
                                this.pispAccount = response.debtorAccount;
                            }
                        }
                        else
                        {
                            this.setState({flow: Flow.CONSENT_MANAGEMENT})
                        }

                        if (Object.keys(response).includes("redirectUri"))
                        {
                            this.setState({redirectUri: response.redirectUri})
                        }
                    }
                }
                else if (Object.keys(response)[0] === "ribUrl" && this.state.flowType === FlowType.ICICI)
                {
                    this.localStorageClear(); // чистим "сохранение"
                    this.initStep(Step.START, response); // готовим "редирект" в RIB
                }
                else if (Object.keys(response)[0] === "redirect")
                {
                    this.initStep(Step.REDIRECT, response); // Перенаправляем пользователя по redirect_uri
                    this.localStorageClear(); // И чистим "сохранение"
                }
                else if (Object.keys(response)[0] === "token" && this.state.step === Step.AUTH)
                {
                    this.initStep(Step.AUTH_SUCCESS);

                    // В случае авторизации PaymentConsent если счет уже был выбран -
                    // то сразу переходим в окно финальной авторизации в TMSX
                    // В случае авторизации bulkConsent функционал выбора счета отсутствует и сразу переходим к финальной авторизации
                    if (((this.state.paymentConsentId || this.state.spoConsentId) && this.pispAccount) || this.state.bulkConsentId)
                    {
                        this.initStep(Step.FINAL_AUTH, response);
                    }
                    else // иначе запрашиваем счета для выбора
                    {
                        setTimeout(() => this.fetchData("get"), 500);
                    }
                }
                else if (Object.keys(response)[0] === "error")
                {
                    this.showError(response.error);
                }
                else
                {
                    this.showError({code: -1});
                }
            })
            .catch(() =>
            {
                this.showError({code: -1});
            })
    };

    /**
     * Стрёмный метод, который везвращает урл для текущего шага по флоу.
     * Надо переделать.
     */
    getStepUrl = (): string =>
    {
        switch (this.state.step)
        {
            case Step.START:
                return this.contextPath + "/api/auth";
            case Step.AUTH:
                return this.contextPath + "/api/auth";
            case Step.AUTH_SUCCESS:
            {
                let url: string = this.contextPath;

                if (this.state.flow === Flow.ACCESS_CONSENT)
                {
                    url += `/api/accounts/${this.state.consentId}`;
                }
                else if (this.state.flow === Flow.PAYMENT_CONSENT && !this.pispAccount)
                {
                    url += `/api/payment-accounts/${this.state.paymentConsentId}`;
                }
                else if (this.state.flow === Flow.PAYMENT_CONSENT && this.pispAccount)
                {
                    url += `/api/authorize-payment`;
                }
                else if (this.state.flow === Flow.BULK_CONSENT)
                {
                    url += `/api/authorize-bulk`;
                }
                else if (this.state.flow === Flow.SPO_CONSENT && !this.pispAccount)
                {
                    url += `/api/spo-accounts/${this.state.spoConsentId}`;
                }
                else if (this.state.flow === Flow.SPO_CONSENT && this.pispAccount)
                {
                    url += `/api/authorize-spo`;
                }
                else if (this.state.flow === Flow.USER_TOKEN)
                {
                    url += `/api/user-token`;
                }
                else
                {
                    url += "/api/consents";
                }
                return url;
            }
            case Step.SELECT_ACCOUNT:
            {
                if (this.state.flow === Flow.PAYMENT_CONSENT)
                    return this.contextPath + "/api/payment/select-account";
                if (this.state.flow === Flow.SPO_CONSENT)
                    return this.contextPath + "/api/spo/select-account";

                return this.contextPath + "/api/authorize-consent";
            }
            case Step.FINAL_AUTH:
            {
                if (this.state.flow === Flow.PAYMENT_CONSENT)
                    return this.contextPath + "/api/authorize-payment";
                else if (this.state.flow === Flow.BULK_CONSENT)
                    return this.contextPath + "/api/authorize-bulk";
                else if (this.state.flow === Flow.SPO_CONSENT)
                    return this.contextPath + "/api/authorize-spo";

                return this.contextPath + "/api/authorize-consent";
            }
            default:
                return this.contextPath;
        }
    };

    /**
     * Получение systemType у бека при загрузке.
     * Должен всегда совпадать с тем, что вшит в html.
     * Не представляю ситуации, когда они вдруг могут не совпасть.
     */
    componentDidMount(): void
    {
        fetch(this.contextPath + "/api/systemtype")
            .then(response =>
            {
                if (response.ok)
                    return response.text();

                throw new Error();
            })
            .then(systemType =>
            {
                if (Object.values(SystemType).includes(systemType) && systemType === this.state.systemType)
                {
                    this.setState({loading: false});
                }
                else
                {
                    this.setState({systemType: SystemType.DEFAULT, loading: false});
                }
            })
            .catch(() =>
            {
                this.showError({code: -1});
            })
    };

    /**
     * Подготовка содержимого страницы в зависимости от текущего шага.
     */
    getStepContent = (): JSX.Element =>
    {
        if (this.state.loading)
            return <Spinner text={<MSGS id="spinners_loading"/>}/>;

        switch (this.state.step)
        {
            case Step.START: {
                return <Hello flow={this.state.flow}
                              consentId={this.state.consentId}
                              paymentConsentId={this.state.paymentConsentId}
                              bulkConsentId={this.state.bulkConsentId}
                              spoConsentId={this.state.spoConsentId}
                              setDebtorAccount={account => {
                                  this.pispAccount = account;
                              }}/>;
            }
            case Step.ERROR: {
                return <ErrorMessage error={this.state.stepData}
                                     onClick={this.hideError}/>;
            }
            case Step.MANAGE_CONSENT: {
                return <ConsentManage tpps={this.state.stepData}
                                      token={this.state.token}/>;
            }
            case Step.USER_TOKEN: {
                return <UserToken userToken={this.state.stepData}
                                      token={this.state.token}/>;
            }
            case Step.AUTH: {
                // if нужен для ICICI, когда мы находимся на шаге авторизации, но ещё не знаем, что надо запросить у пользователя.
                if (!this.state.stepData || JSON.stringify(this.state.stepData) === '{}')
                    return <Spinner text={<MSGS id="spinners_loading"/>}/>;

                return <RequestParams params={this.state.stepData}
                                      setInputKeys={inputKeys => {
                                          this.inputKeys = inputKeys
                                      }}/>;
            }
            case Step.SELECT_ACCOUNT: {
                return <AccountSelect single={(!!this.state.paymentConsentId || !!this.state.spoConsentId)}
                                      items={this.state.stepData}
                                      setSelectedAccounts={selected => {
                                          this.selectedAccounts = selected
                                      }}/>;
            }
            case Step.FINAL_AUTH: {
                return <Hello flow={this.state.flow}
                              consentId={this.state.consentId}
                              paymentConsentId={this.state.paymentConsentId}
                              bulkConsentId={this.state.bulkConsentId}
                              spoConsentId={this.state.spoConsentId}
                              token={this.state.token}
                              setDebtorAccount={account => {
                                  this.pispAccount = account;
                              }}/>;
            }
            case Step.REDIRECT: {
                // В лог выводим для нужд тестирования, чтобы было удобно скопировать код в постман.
                console.log(this.state.stepData.authorizationCode);
                return <Redirect redirectUri={this.state.redirectUri}
                                 authorizationCode={this.state.stepData.authorizationCode}/>;
            }
            case Step.AUTH_SUCCESS: {
                // "Сохраняемся" после успешной авторизации
                localStorage.setItem("AuthPageState", JSON.stringify(this.state));
                localStorage.setItem("AuthPageTime", new Date().toString());

                // И переходим в состояние ожидания ответа от TMSX. Он сообщит, что делать дальше.
                let text = <MSGS id="spinners_request_accounts"/>;
                if (this.pispAccount) {
                    text = <MSGS id="spinners_process_authorize_payment"/>;
                } else if (!this.state.consentId && !this.state.paymentConsentId && !this.state.bulkConsentId && !this.state.spoConsentId) {
                    text = <MSGS id="spinners_request_consents"/>;
                }
                return <Spinner text={text}/>;
            }
        }

        return <ErrorMessage error={{code: -1}} onClick={this.hideError}/>;
    };

    render()
    {
        let content = this.getStepContent();
        let form : JSX.Element;

        // Для ICICI рисуем "магическую" кнопку, по которой уйдет POST-запрос на их url.
        // Нет, просто открыть их урл нельзя.
        if (this.state.step === Step.START && this.state.flowType === FlowType.ICICI)
        {
            form =
                <form autoComplete={"off"} action={this.state.stepData.ribUrl} method={"post"}>
                    <input type={"hidden"} name={"request"} value={this.state.stepData.request}/>
                    <input type={"hidden"} name={"encKey"} value={this.state.stepData.encKey}/>
                    {content}
                </form>
        }
        else
        {
            form =
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    {content}
                </form>
        }

        return (
            <div className="main-form">
                <Logo systemType={this.state.systemType}/>
                {form}
            </div>
        );
    }
}