import React from "react";
import {Accordion, Button, Card, Confirm, List} from 'semantic-ui-react'
import Spinner from "../forms/Spinner";
import {getContextPath} from "../Utils";
import {FormattedHTMLMessage as MSGS, useIntl} from "react-intl";

interface UserToken {
    tokenStatus: any
    expirationDateTime?: string
    value?: string
}

interface IProperties {
    userToken?: UserToken
    token?: string
}

interface IState {
    loading: boolean
    open: boolean
    userToken: UserToken
}

let NOT_EXISTS = <MSGS id="user_token_not_exists"/>;

/**
 * Карточка, отображающая информацию о пользовательском токене.
 * Можно сгенерировать новый токен, или отозвать существующий.
 */
export default class CardUserToken extends React.Component<IProperties, IState> {

    state: IState;

    constructor(props: Readonly<IProperties>)
    {
        super(props);

        let userToken= {
            tokenStatus: NOT_EXISTS
        };

        if (props.userToken)
        {
            userToken = props.userToken;
        }

        this.state = {
            loading: false,
            open: false,
            userToken: userToken,
        };
    }

    /**
     * Показать диалог подтверждения генерации/удаления токена
     */
    showConfirmation = () =>
    {
        this.setState({ open: true });
    };

    /**
     * Скрыть диалог
     */
    handleCancel = () =>
    {
        this.setState({ open: false });
    };

    /**
     * Отправка запроса на создание/удаление токена
     */
    revokeOrGenerateToken = () =>
    {
        this.setState({ loading: true, open: false });

        let headers: any = {};
        if (this.props.token)
            headers["Authorization"] = "Bearer " + this.props.token;

        let method: string = this.state.userToken.tokenStatus !== NOT_EXISTS ? "DELETE" : "POST";

        fetch(getContextPath() + "/api/user-token", {
                method: method,
                headers: headers,
            })
            .then(response =>
            {
                if (response.ok)
                    return response.json();

                return undefined;
            })
            .then(response =>
            {
                // todo catch errors

                let userToken;
                if (response && response.tokenStatus)
                {
                    userToken = response;
                }
                else
                {
                    userToken = {
                        tokenStatus: NOT_EXISTS
                    }
                }

                this.setState({ loading: false, open: false, userToken: userToken });
            })
    };

    render()
    {
        let userToken = this.state.userToken;

        if (this.state.loading)
        {
            return (
                <Spinner text={<MSGS id="user_token_loading"/>}/>
            );
        }

        return(
            <Card fluid>
                <Card.Content>
                    <Card.Header>{userToken.tokenStatus}</Card.Header>
                    <Card.Description>
                        {userToken.tokenStatus !== NOT_EXISTS ? <MSGS id="user_token_expiration" values={{expiration_time: userToken.expirationDateTime}}/> : null}
                    </Card.Description>
                </Card.Content>
                {userToken.tokenStatus !== NOT_EXISTS ?
                    <Card.Content extra>
                        <TokenAccordion token={userToken.value}/>
                    </Card.Content>
                    :
                    null
                }
                <Card.Content extra>
                    <Button onClick={this.showConfirmation}
                            type={"button"}
                            fluid
                            basic
                            color='red'>
                        {userToken.tokenStatus !== NOT_EXISTS ? <MSGS id="user_token_revoke_button"/> : <MSGS id="user_token_generate_button"/>}
                    </Button>
                    <ConfirmDialog exists={userToken.tokenStatus !== NOT_EXISTS}
                                   open={this.state.open}
                                   handleCancel={this.handleCancel}
                                   revokeOrGenerateToken={this.revokeOrGenerateToken}/>
                </Card.Content>
            </Card>
        );
    }
}

const ConfirmDialog: React.FC<{exists: boolean, open: boolean,
    handleCancel: () => void, revokeOrGenerateToken: () => void}> = ({exists, open, handleCancel, revokeOrGenerateToken}) =>
{
    const intl = useIntl();

    let confirmationText = intl.formatHTMLMessage({id: (exists ? "user_token_revoke_text" : "user_token_generate_text")});

    return (
        <Confirm open={open}
                 content={confirmationText}
                 cancelButton={intl.formatHTMLMessage({id: "user_token_confirmation_no"})}
                 confirmButton={intl.formatHTMLMessage({id: "user_token_confirmation_yes"})}
                 onCancel={handleCancel}
                 onConfirm={revokeOrGenerateToken} />
    );
};

const TokenAccordion: React.FC<{token: string | undefined}> = ({token}) =>
{
    const intl = useIntl();

    let rootAccordion = Array<any>();
    let usertokenAccordion: any = {};
    usertokenAccordion["key"] = "usertoken";
    usertokenAccordion["title"] = intl.formatHTMLMessage({id: "user_token_token"});
    usertokenAccordion["content"] = {content: <List divided verticalAlign='middle'>
            <List.Item>
                <List.Content className={"word-wrap"}>
                    <List.Header>{token}</List.Header>
                </List.Content>
            </List.Item>
        </List>};

    rootAccordion.push(usertokenAccordion);

    return (
        <Accordion panels={rootAccordion} exclusive={false}/>
    );
};