import React from 'react';

interface IProperties {
    systemType: string
}

/**
 * Логотип банка
 */
export default class Logo extends React.Component<IProperties> {

    render()
    {
        return (
            <div className={"page-header bank-logo-wrap"}>
                <img src={process.env.PUBLIC_URL + "/logo/" + this.props.systemType + ".png"}
                     className="ui image bank-logo"
                     alt="logo"/>
            </div>
        )
    }
}