import React from "react";
import {Button} from "semantic-ui-react";
import {FormattedHTMLMessage as MSGS} from "react-intl";

interface IProperties {
    error: {
        code: number;
        noRetry?: boolean;
        message?: string;
        secondaryMessage?: string;
    };
    onClick: (event: any) => void;
}

export default class ErrorMessage extends React.Component<IProperties> {

    render()
    {
        if ((this.props.error.code === 500) || (this.props.error.code === -1))
        {
            return (
                <div>
                    <div className={"app-text"}><MSGS id="errors_server_first_line"/></div>
                    <div className={"app-text"}><MSGS id="errors_server_second_line"/></div>
                </div>
            )
        }

        if (this.props.error.noRetry)
        {
            return (
                <div>
                    <div className={"app-text"}>{this.props.error.message}</div>
                </div>
            )
        }

        if (this.props.error.code === 404)
            return <div className={"app-text"}><MSGS id="errors_not_found"/></div>;


        if (this.props.error.message)
        {
            let messageElement = new Array<JSX.Element>();
            messageElement.push(<div className={"app-text"} key={"message"}>{this.props.error.message}</div>)
            if (this.props.error.secondaryMessage){
                messageElement.push(<div className={"app-text"} key={"secondaryMessage"}>{this.props.error.secondaryMessage}</div>)
            }

            return (
                <div>
                    {messageElement}
                    <div className={"app-text"}><MSGS id="errors_tmsx_second_line"/></div>
                    <div className={"page-footer"}>
                        <Button onClick={this.props.onClick}
                                type={"button"}
                                primary fluid
                                content={<MSGS id="errors_tmsx_button"/>}/>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div className={"app-text"}><MSGS id="errors_internal_first_line"/></div>
                <div className={"app-text"}><MSGS id="errors_internal_second_line"/></div>
                <div className={"page-footer"}>
                    <Button onClick={this.props.onClick}
                            type={"button"}
                            primary fluid
                            content={<MSGS id="errors_internal_button"/>}/>
                </div>
            </div>
        )
    }
}