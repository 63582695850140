import React from 'react';
import {Button} from "semantic-ui-react";
import CardUserToken from "../components/CardUserToken";
import {FormattedHTMLMessage as MSGS} from "react-intl";

interface IProperties {
    userToken?: any,
    token?: string;
}

/**
 * Страница отображения пользовательских токенов.
 */
export default class UserToken extends React.Component<IProperties> {

    render()
    {
        return (
            <div>
                <div className={"app-text"}><MSGS id="user_token_first_line"/></div>
                <CardUserToken userToken={this.props.userToken} token={this.props.token}/>
                <div className={"page-footer"}>
                    <Button type="submit" primary fluid content={<MSGS id="user_token_button"/>}/>
                </div>
            </div>
        )
    }
}