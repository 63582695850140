import React from 'react';
import CardSelect from "../components/CardSelect";
import {Button} from "semantic-ui-react";
import {AccountSelectItem} from "../view/CommonPage";
import {FormattedHTMLMessage as MSGS} from "react-intl";

interface IProperties {
    single?: boolean
    items: Array<AccountSelectItem>
    setSelectedAccounts: (selected: Set<AccountSelectItem>) => void
}

interface IState {
    selectedItems: Set<AccountSelectItem>
    disabled: boolean
}

/**
 * Страница выбора счетов. Может быть выбран один или несколько счетов, в зависимости от параметра.
 */
export default class AccountSelect extends React.Component<IProperties, IState> {

    state: IState = {
        selectedItems: new Set<AccountSelectItem>(),
        disabled: true
    };

    render()
    {
        let items = this.props.items.map((item, count) =>
        {
            let selected = this.state.selectedItems.has(item);
            return (
                <CardSelect key={count} account={item} selected={selected} onClick={(checked) =>
                {
                    let items: Set<AccountSelectItem> = this.state.selectedItems;

                    if (this.props.single)
                        items.clear();

                    if (checked)
                        items.add(item);
                    else
                        items.delete(item);

                    this.props.setSelectedAccounts(items);
                    this.setState({selectedItems : items, disabled : items.size === 0});
                }}/>
            )
        });

        let text = (this.props.single) ? <MSGS id="select_accounts_single"/> : <MSGS id="select_accounts_multiple"/>;

        return (
            <div>
                <div className={"app-text"}>{text}</div>
                {items}
                <div className={"page-footer"}>
                    <Button type="submit" disabled={this.state.disabled} primary fluid content={<MSGS id="select_accounts_button"/>}/>
                </div>
            </div>
        )
    }

}