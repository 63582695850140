import React from "react";
import {Icon, Input, Popup} from "semantic-ui-react"

interface IProperties {
    item: {
        id: string;
        placeholder: string;
        description: string;
        pattern: string;
        secret: boolean;
    };
}

/**
 * Поле ввода параметра, запрошенного TMSX на шаге авторизации
 */
export default class TextInput extends React.Component<IProperties> {

    render()
    {
        return (
            <Input id={this.props.item.id}
                   fluid
                   autoComplete={"off"}
                   type={"text"}
                   className={this.props.item.secret ? "password" : ""}
                   pattern={this.props.item.pattern}
                   required={true}
                   icon={<Popup trigger={<Icon name='question' circular link />}
                                content={this.props.item.description}
                                on={'click'}
                                position={'top right'}
                                hideOnScroll/>}
                   placeholder={this.props.item.placeholder}/>
        );
    }
}