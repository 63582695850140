import * as default_en from './messages/default_en.json';
import * as albaraka_en from './messages/albaraka_en.json';
import * as bbk_en from './messages/bbk_en.json';
import * as ithmaar_ar from './messages/ithmaar_ar.json';
import * as ithmaar_en from './messages/ithmaar_en.json';

const messages : any = {
    'default': {
        'en': default_en
    },
    'albaraka': {
        'en': albaraka_en
    },
    'bbk': {
        'en': bbk_en
    },
    'ithmaar': {
        'ar': ithmaar_ar,
        'en': ithmaar_en
    }
};

export enum SystemType
{
    DEFAULT = "default",
    ALBARAKA = "albaraka",
    ALSALAM = "alsalam",
    BBK = "bbk",
    ESKAN = "eskan",
    HBL = "hbl",
    HBTF = "hbtf",
    ICICI = "icici",
    ITHMAAR = "ithmaar",
}

// Используется вместо URLSearchParam, ибо IE
export let urlParam = (name: any) =>
{
    let results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null)
        return null;
    return decodeURIComponent(results[1]) || null;
};

export let getContextPath = () =>
{
    let pathname = window.location.pathname;
    let path = pathname.substr(1);

    if (pathname === "/")
        return "";

    if (path.indexOf("/") >= 0)
        return pathname.substr(0, path.indexOf("/") + 1);

    return pathname;
};

// Вытаскиваем вшитое значение из html
export let getSystemType = (): string =>
{
    let meta: any = document.querySelector('meta[name="systemType"]');
    if (meta)
        return meta.content;

    return SystemType.DEFAULT;
};

// Вытаскиваем вшитое значение из html
export let getVersion = (): string =>
{
    let meta: any = document.querySelector('meta[name="version"]');
    if (meta)
        return meta.content;

    return "undefined";
};

// Локализация по типу системы
export let getMessages = (locale: string) =>
{
    let subsystem = getSystemType();
    let group = messages[subsystem];

    if (!group)
        group = messages['default'];

    let msg = group[locale];
    if (!msg)
        msg = group['en'];

    return msg;
};

export let canChangeLocale = () =>
{
    let subsystem = getSystemType();
    let group = messages[subsystem];

    return group && Object.keys(group).length > 1;
}