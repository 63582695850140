import React from "react";
import {Button, Card, Confirm, Dimmer, List, Loader, Message} from 'semantic-ui-react'
import {getContextPath} from "../Utils";
import {FormattedHTMLMessage as MSGS, useIntl} from "react-intl";
import {IConsentDetails} from "../forms/ConsentManage";

enum Status {
    EXIST,      // TPP существует и загружена
    DELETING,   // Отправлен запрос на удаление всех консентов
    MESSAGE,    // TPP удалена, отображается сообщение
    DELETED,    // Сообщение скрыто
}

interface IProperties {
    tppCode: string
    consents: Array<IConsentDetails>
    token?: string
    onClick: (tppCode: string) => void
}

interface IState {
    status: Status
    open: boolean
}

/**
 * Карточка, отображающая информацию о TPP и количестве её консетов.
 * Можно отменить (удалить) все консенды для TPP.
 */
export default class CardTpp extends React.Component<IProperties, IState> {

    state: IState = {
        status: Status.EXIST,
        open: false,
    };

    handleClick = () =>
    {
        this.props.onClick(this.props.tppCode);
    };

    /**
     * Показать диалог отмены всех консентов
     */
    showConfirmation = () =>
    {
        this.setState({ open: true });
    };

    /**
     * Отмена удаления консентов
     */
    handleCancel = () =>
    {
        this.setState({ open: false });
    };

    /**
     * Отправка запроса на удаление консентов
     */
    deleteTpp = () =>
    {
        this.setState({ status: Status.DELETING, open: false });

        let headers: any = {};
        if (this.props.token)
        {
            headers["Authorization"] = "Bearer " + this.props.token;
        }

        fetch(getContextPath() + "/api/delete-tpp/" + encodeURIComponent(this.props.tppCode), {
                method: "DELETE",
                headers: headers,
            })
            .then(response => response.json())
            .then(response =>
            {
                // todo catch errors
                this.setState({ status: Status.MESSAGE, open: false });
            })
    };

    /**
     * Скрыть сообшение об успешном удалении tpp
     */
    handleDismiss = () =>
    {
        this.setState({ status: Status.DELETED, open: false });
    };

    render()
    {
        if (this.state.status === Status.DELETED)
            return null;

        if (this.state.status === Status.MESSAGE)
        {
            return (
                <Message onDismiss={this.handleDismiss}>
                    <Message.Header>{<MSGS id="consent_manage_tpp_canceled_header"/>}</Message.Header>
                    <p>{<MSGS id="consent_manage_tpp_canceled_body"/>}</p>
                </Message>
            );
        }

        let extraContent = new Array<JSX.Element>();
        if (this.state.status === Status.DELETING)
        {
            extraContent.push(
                <Card.Content extra key={0}>
                    <Dimmer active inverted>
                        <Loader inverted>{<MSGS id="consent_manage_tpp_process_cancel"/>}</Loader>
                    </Dimmer>
                </Card.Content>
            );
        }
        else
        {
            let consents = this.props.consents.map(value => <List.Item as='li'>{value.consentId}</List.Item>);
            extraContent.push(
                <Card.Content extra key={0} onClick={this.handleClick} fluid as={"a"}>
                    <List as='ul'>
                        {consents}
                    </List>
                </Card.Content>,
                <Card.Content extra>
                    <Button onClick={this.showConfirmation}
                            type={"button"}
                            fluid
                            basic
                            color='red'>
                        {<MSGS id="consent_manage_tpp_button"/>}
                    </Button>
                    <ConfirmDialog tppCode={this.props.tppCode}
                                   open={this.state.open}
                                   handleCancel={this.handleCancel}
                                   deleteTpp={this.deleteTpp}/>
                </Card.Content>
            );
        }

        return (
            <Card fluid onClick={() => {}}>
                <Card.Content onClick={this.handleClick} fluid as={"a"}>
                    <Card.Header>{this.props.tppCode}</Card.Header>
                </Card.Content>
                {extraContent}
            </Card>
        );
    }
}

const ConfirmDialog: React.FC<{tppCode: string, open: boolean,
    handleCancel: () => void, deleteTpp: () => void}> = ({tppCode, open, handleCancel, deleteTpp}) =>
{
    const intl = useIntl();

    let confirmationText = intl.formatHTMLMessage({id: "consent_manage_tpp_confirmation_text"},
        {tppCode: tppCode});

    return (
        <Confirm open={open}
                 content={confirmationText}
                 cancelButton={intl.formatHTMLMessage({id: "consent_manage_tpp_confirmation_no"})}
                 confirmButton={intl.formatHTMLMessage({id: "consent_manage_tpp_confirmation_yes"})}
                 onCancel={handleCancel}
                 onConfirm={deleteTpp} />
    );
};