import React from "react";
import {Divider, Grid, Header, Icon, Segment} from "semantic-ui-react";
import {FormattedHTMLMessage as MSGS, useIntl} from "react-intl";

interface IProperties {
    payment: {
        amount: number
        currency: string
        creditor: string
        exchangeRate?: number
        exchangeRateType?: string
        exchangeRateBuyRate?: {
            currency: string
            value: number
        }
        exchangeRateSellRate?: {
            currency: string
            value: number
        }
        charge?: {
            currency: string
            amount: number
            chargeBearer: string
        }
        account?: {
            code: string
            type: string
        }
    }
}

/**
 * Карточка, отображающая информацию о платеже, который проходит авторизацию.
 */
export default class CardPayment extends React.Component<IProperties> {

    render()
    {
        let from = new Array<JSX.Element>();
        if (this.props.payment.account)
        {
            from.push(
                <Divider vertical key={0}><Icon name='arrow right' /></Divider>,
                <Grid.Column key={1}>
                    {<MSGS id="welcome_page_payment_consent_from"/>}
                    <Header className={"word-wrap"}>
                        {this.props.payment.account.code}
                    </Header>
                </Grid.Column>
            );
        }
        
        let amountElement = new Array<JSX.Element>();
        if (this.props.payment.exchangeRate)
        {
            amountElement.push(
                <div className={"app-subtext"} key={"exchangeRate"}> {<MSGS id="welcome_page_payment_consent_exchange_rate"/>} {this.props.payment.exchangeRate}</div>,
                <div className={"app-subtext"} key={"exchangeRateType"}> {<MSGS id="welcome_page_payment_consent_exchange_rate_type"/>} {this.props.payment.exchangeRateType}</div>,
                <div className={"app-text"} key={"paymentAmount"}> {<MSGS id="welcome_page_payment_consent_converted_amount"/>} {this.props.payment.amount} {this.props.payment.currency}</div>
            );


        }
        else if (this.props.payment.exchangeRateBuyRate || this.props.payment.exchangeRateSellRate)
        {
            amountElement.push(
                <div className={"app-subtext"} key={"exchangeRateType"}> {<MSGS id="welcome_page_payment_consent_exchange_rate_type"/>} {this.props.payment.exchangeRateType}</div>,
            )

            if (this.props.payment.exchangeRateBuyRate)
            {
                amountElement.push(<div className={"app-subtext"} key={"exchangeRateBuy"}>{this.props.payment.exchangeRateBuyRate.currency}{<MSGS id="welcome_page_payment_consent_exchange_rate_buy"/>}{this.props.payment.exchangeRateBuyRate.value}</div>);
            }

            if (this.props.payment.exchangeRateSellRate)
            {
                amountElement.push(<div className={"app-subtext"} key={"exchangeRateSell"}>{this.props.payment.exchangeRateSellRate.currency}{<MSGS id="welcome_page_payment_consent_exchange_rate_sell"/>}{this.props.payment.exchangeRateSellRate.value}</div>);
            }

            amountElement.push(<div className={"app-text"} key={"paymentAmount"}> {<MSGS id="welcome_page_payment_consent_converted_amount"/>} {this.props.payment.amount} {this.props.payment.currency}</div>)
        }
        else
        {
            amountElement.push(
                <div className={"app-text"} key={"paymentAmount"}>{this.props.payment.amount} {this.props.payment.currency}</div>
            )
        }
        let chargesSegment;
        if (this.props.payment.charge)
        {
            let chargeBearerText;
            if (this.props.payment.charge.chargeBearer ==='DEBT')
            {
                chargeBearerText = 'welcome_page_payment_consent_charge_bearer_our';
            }
            else if (this.props.payment.charge.chargeBearer ==='CRED')
            {
                chargeBearerText = 'welcome_page_payment_consent_charge_bearer_ben';
            }
            else 
            {
                chargeBearerText = 'welcome_page_payment_consent_charge_bearer_sha';
            }

            chargesSegment = 
            <Segment>
                <div className={"app-subtext"}> {<MSGS id="welcome_page_payment_consent_charges"/>}</div>
                <div className={"app-subtext"}>{this.props.payment.charge.amount} {this.props.payment.charge.currency}</div>
                <div className={"app-subtext"}><MSGS id={chargeBearerText}/></div>
            </Segment>
        }

        return (
            <div>
                <div className={"app-text"}><MSGS id="welcome_page_payment_consent_first_line"/></div>
                <Segment >
                    {amountElement}
                    {chargesSegment}
                    <Segment>
                        <Grid columns={2} stackable textAlign='center'>
                            <Grid.Row verticalAlign='middle'>
                                {from}
                                <Grid.Column>
                                    {<MSGS id="welcome_page_payment_consent_to"/>}
                                    <Header>
                                        {this.props.payment.creditor}
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Segment>
            </div>
        )
    }
}