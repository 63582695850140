import React from 'react';
import {Dimmer, Loader, Placeholder, Segment} from "semantic-ui-react";

interface IProperties {
    text: string | JSX.Element
}

export default class Spinner extends React.Component<IProperties> {

    render() {
        return (
            <Segment>
                <Dimmer active inverted>
                    <Loader inverted>{this.props.text}</Loader>
                </Dimmer>
                <Placeholder>
                    <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Paragraph>
                </Placeholder>
            </Segment>
        )
    }
}