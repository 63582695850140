import React from "react";
import {Button, Message} from "semantic-ui-react";
import {FormattedHTMLMessage as MSGS} from "react-intl";

interface IProperties {
    redirectUri?: string;
    authorizationCode: string;
}

/**
 * Страница перенаправление пользователя в TPP после авторизации консента.
 * Если был передан параметр redirectUri, будет отображаться кнопка редиректа.
 */
export default class Redirect extends React.Component<IProperties> {

    render()
    {
        if (this.props.redirectUri)
        {
            let urlString  = this.props.redirectUri;
            if (!urlString.startsWith("http"))
                urlString = "https://" + urlString;

            let url = new URL(urlString);
            url.searchParams.set("code", this.props.authorizationCode);

            return (
                <div>
                    <div className={"app-text"}><MSGS id="redirect_first_line"/></div>
                    <div className={"page-footer"}>
                        <Button fluid
                                href={url}
                                content={<MSGS id="redirect_button"/>}/>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div className={"app-text"}><MSGS id="redirect_first_line"/></div>
                <div className={"app-text"}><MSGS id="redirect_auth_code"/></div>
                <Message className={"word-wrap"}>
                    {this.props.authorizationCode}
                </Message>
            </div>
        )
    }
}