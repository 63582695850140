import * as React from 'react';
import App from './App';
import { IntlProvider } from 'react-intl';
import {getMessages} from "./Utils";

interface State { locale: string }

const defaultLocale = 'en';

class LanguageContainer extends React.Component<{}, State> {
    constructor(props: {}) {
        super(props);

        let locale = localStorage.getItem("AuthPageLocale");
        if (!locale)
            locale = defaultLocale;

        this.state = {
            locale: locale
        }
    }

    public changeLanguage = (locale: string) => {
        if (!locale)
            locale = defaultLocale;

        localStorage.setItem("AuthPageLocale", locale);
        this.setState({ locale : locale });
    };

    public render() {
        return (
            <IntlProvider key={this.state.locale} locale={this.state.locale} messages={getMessages(this.state.locale)}>
                <App changeLanguage={this.changeLanguage} currentLocale={this.state.locale}/>
            </IntlProvider>
        );
    }
}

export default LanguageContainer;