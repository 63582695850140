import React from 'react';
import CardConsent from "../components/CardConsent";
import {Button} from "semantic-ui-react";
import {FormattedHTMLMessage as MSGS} from "react-intl";
import CardTpp from "../components/CardTpp";

export interface Account {
    name: string
    code: string
    type: string
}

export interface IConsentDetails {
    consentId: string
    accounts: Array<Account>
    permissions: Array<string>
    authorizationDate: string
    expirationDate: string
}

export interface ITppDetails {
    code: string
    consents: Array<IConsentDetails>
}

interface IProperties {
    tpps: Array<ITppDetails>,
    token?: string;
}

interface IState {
    selectedTpp?: string;
    tpps: Array<ITppDetails>,
}

/**
 * Страница управления консентами.
 */
export default class ConsentManage extends React.Component<IProperties, IState> {

    state: IState = {
        selectedTpp: '',
        tpps: this.props.tpps,
    };

    selectTpp = (tppCode : string) =>
    {
        this.setState({selectedTpp : tppCode});
    };

    render()
    {
       
        if (this.state.selectedTpp) {
            let tpp = this.state.tpps.find(value => {return value.code === this.state.selectedTpp;});
            let consents = (tpp ? tpp.consents : []);
            let items = consents.map(item =>
                <CardConsent key={item.consentId} tppCode={this.state.selectedTpp || ''} item={item} token={this.props.token} onClick={this.selectTpp}
                    onDelete={() => {
                        const index = consents.indexOf(item, 0);
                        if (index > -1) {
                            consents.splice(index, 1);
                        }
                    }}/>);
            
            return (
                <div>
                    <div className={"app-text"}><MSGS id="consent_manage_first_line"/></div>
                    {items}
                    <Button type="button" basic fluid color='blue' content={<MSGS id="consent_manage_tpp_button_back"/>} onClick={() => this.selectTpp('')}/>
                    <div className={"page-footer"}>
                        <Button type="submit" primary fluid content={<MSGS id="consent_manage_button"/>}/>
                    </div>
                </div>
            )
        }
        else {
            let items = this.state.tpps
                .filter(item => item.consents && item.consents.length > 0)
                .map(item => <CardTpp tppCode={item.code} consents={item.consents} token={this.props.token} onClick={this.selectTpp}/>);
            
            return (
                <div>
                    <div className={"app-text"}><MSGS id="consent_manage_first_line"/></div>
                    {items}
                    <div className={"page-footer"}>
                        <Button type="submit" primary fluid content={<MSGS id="consent_manage_button"/>}/>
                    </div>
                </div>
            )
        }
    }
}