import React from "react";
import {Card} from "semantic-ui-react";

interface IProperties {
    account: {
        name: string
        code: string
        type: string
    }
    selected: boolean
    onClick: (selected: boolean) => void
}

/**
 * Карточка, отображающая информацию о счете/карте клиента.
 */
export default class CardSelect extends React.Component<IProperties> {

    handleClick = () =>
    {
        this.props.onClick(!this.props.selected);
    };

    render()
    {
        return (
            <Card onClick={this.handleClick}
                  fluid
                  as={"a"}>
                <Card.Content className={this.props.selected ? "selected" : "not-selected"}>
                    <Card.Header>{this.props.account.name}</Card.Header>
                    <Card.Description className={"word-wrap"}>{this.props.account.code}</Card.Description>
                </Card.Content>
            </Card>
        )
    }
}