import React from "react";
import {Button} from "semantic-ui-react";
import Spinner from "./Spinner";
import CardPayment from "../components/CardPayment";
import CardBulkPayment from "../components/CardBulkPayment"
import CardSpo from "../components/CardSpo"
import ErrorMessage from "./ErrorMessage";
import {Flow} from "../view/CommonPage";
import {getContextPath} from "../Utils";
import {FormattedHTMLMessage as MSGS} from "react-intl";

interface IProperties {
    flow: Flow,
    consentId?: string
    paymentConsentId?: string
    bulkConsentId?: string
    spoConsentId?: string
    token?: string
    setDebtorAccount: (account: string) => void
}

interface IState {
    error?: boolean
    payment?: {
        amount: number
        currency: string
        creditor: string
        account?: {
            code: string
            type: string
        }
    }
    batchDetails?: any
    spoDetails?: any
    errorData?: any
}

/**
 * Стартовая страница перед авторизацией. В зависимости от параметра "flow" будет отображаться разная информация:
 *      - управление консентами
 *      - авторизация консента
 *      - авторизация платежа
 *      - управление пользовательским токеном
 */
export default class Hello extends React.Component<IProperties, IState> {
    state: IState = {};

    /**
     * Отправка запроса на получение информации о платеже, если необходимо
     */
    componentDidMount(): void
    {
        if (this.props.flow === Flow.PAYMENT_CONSENT)
        {
            let headers: any = {};
            if (this.props.token)
                headers["Authorization"] = "Bearer " + this.props.token;
        
            fetch(getContextPath() + `/api/payment/${this.props.paymentConsentId}`,
                {
                    method: "get",
                    headers: headers,
                })
                .then(response =>
                {
                    if (response.ok)
                        return response.json();

                    throw new Error();
                })
                .then(response =>
                {
                    if (Object.keys(response)[0] === "error")
                    {
                        this.setState({error : true, payment : undefined, batchDetails : undefined, spoDetails : undefined, errorData : response.error});
                    }
                    else if (response.account)
                    {
                        // Если TMSX вернул счет - сохраняем его,
                        // чтобы сразу после авторизации пользователя отправить запрос на авторизацию платежа
                        this.props.setDebtorAccount(response.account.code);
                    }
                    this.setState({payment : response})
                })
                .catch(() =>
                {
                    this.setState({error : true, payment : undefined, batchDetails : undefined, spoDetails : undefined, errorData : {code: -1}})
                })
        }
        else if (this.props.flow === Flow.BULK_CONSENT)
        {
            let headers: any = {};
            if (this.props.token)
                headers["Authorization"] = "Bearer " + this.props.token;

                fetch(getContextPath() + `/api/bulk/${this.props.bulkConsentId}`,
                {
                    method: "get",
                    headers: headers,
                })
                .then(response =>
                {
                    if (response.ok)
                        return response.json();

                    throw new Error();
                })
                .then(response =>
                {
                    if (Object.keys(response)[0] === "error")
                    {
                        this.setState({error : true, payment : undefined, batchDetails : undefined, spoDetails : undefined, errorData : response.error});
                    }
                    this.setState({batchDetails : response.batchDetails})
                })
                .catch(() =>
                {
                    this.setState({error : true, payment : undefined, batchDetails : undefined, spoDetails : undefined, errorData : {code: -1}})
                })
        }
        else if (this.props.flow === Flow.SPO_CONSENT)
        {
            let headers: any = {};
            if (this.props.token)
                headers["Authorization"] = "Bearer " + this.props.token;
        
            fetch(getContextPath() + `/api/spo/${this.props.spoConsentId}`,
                {
                    method: "get",
                    headers: headers,
                })
                .then(response =>
                {
                    if (response.ok)
                        return response.json();

                    throw new Error();
                })
                .then(response =>
                {
                    if (Object.keys(response)[0] === "error")
                    {
                        this.setState({error : true, payment : undefined, batchDetails : undefined, spoDetails : undefined, errorData : response.error});
                    }
                    else if (response.account)
                    {
                        // Если TMSX вернул счет - сохраняем его,
                        // чтобы сразу после авторизации пользователя отправить запрос на авторизацию SPO
                        this.props.setDebtorAccount(response.account.code);
                    }
                    this.setState({spoDetails : response})
                })
                .catch(() =>
                {
                    this.setState({error : true, payment : undefined, batchDetails : undefined, spoDetails : undefined, errorData : {code: -1}})
                })
        }
    }

    render()
    {
        let content;

        if (this.props.flow === Flow.USER_TOKEN)
        {
            content =
                <div>
                    <div className={"app-text"}><MSGS id="welcome_page_user_token_first_line"/></div>
                    <div className={"app-text"}><MSGS id="welcome_page_user_token_second_line"/></div>
                </div>;
        }
        else if (this.props.flow === Flow.ACCESS_CONSENT)
        {
            content =
                <div>
                    <div className={"app-text"}><MSGS id="welcome_page_access_consent_first_line"/></div>
                    <div className={"app-text"}><MSGS id="welcome_page_access_consent_second_line"/></div>
                </div>;
        }
        else if (this.props.flow === Flow.PAYMENT_CONSENT)
        {
            if (this.state.error)
            {

                return (
                    <ErrorMessage error={this.state.errorData} onClick={(event) =>
                    {
                        localStorage.clear();
                        window.location.reload();}
                    }/>
                )
            }
            else if (!this.state.payment)
            {
                return (
                    <div>
                        <div className={"app-text"}><MSGS id="welcome_page_payment_consent_first_line"/></div>
                        <Spinner text={<MSGS id="spinners_load_payment_details"/>}/>
                    </div>
                )
            }

            content = <CardPayment payment={this.state.payment}/>;
        }
        else if (this.props.flow === Flow.BULK_CONSENT)
        {
            if (this.state.error)
            {

                return (
                    <ErrorMessage error={this.state.errorData} onClick={(event) =>
                    {
                        localStorage.clear();
                        window.location.reload();}
                    }/>
                )
            }
            else if (!this.state.batchDetails)
            {
                return (
                    <div>
                        <div className={"app-text"}><MSGS id="welcome_page_bulk_payment_consent_first_line"/></div>
                        <Spinner text={<MSGS id="spinners_load_bulk_payment_details"/>}/>
                    </div>
                )
            }

            content = <CardBulkPayment batchDetails={this.state.batchDetails}/>;
        }
        else if (this.props.flow === Flow.SPO_CONSENT)
        {
            if (this.state.error)
            {

                return (
                    <ErrorMessage error={this.state.errorData} onClick={(event) =>
                    {
                        localStorage.clear();
                        window.location.reload();}
                    }/>
                )
            }
            else if (!this.state.spoDetails)
            {
                return (
                    <div>
                        <div className={"app-text"}><MSGS id="welcome_page_spo_payment_consent_first_line"/></div>
                        <Spinner text={<MSGS id="spinners_load_spo_details"/>}/>
                    </div>
                )
            }

            content = <CardSpo spoDetails={this.state.spoDetails}/>;
        }
        else
        {
            content =
                <div>
                    <div className={"app-text"}><MSGS id="welcome_page_consent_management_first_line"/></div>
                    <div className={"app-text"}><MSGS id="welcome_page_consent_management_second_line"/></div>
                </div>;
        }

        return (
            <div>
                {content}
                <div className={"page-footer"}>
                    <Button type="submit" primary fluid content={<MSGS id="welcome_page_button"/>}/>
                </div>
            </div>
        )
    }
}