import React from "react";
import {Accordion, Button, Card, Confirm, Dimmer, List, Loader, Message} from 'semantic-ui-react'
import {getContextPath} from "../Utils";
import {FormattedHTMLMessage as MSGS, useIntl} from "react-intl";
import {IConsentDetails, Account} from "../forms/ConsentManage";

enum Status {
    EXIST,      // Консент существует и загружен
    DELETING,   // Отправлен запрос на удаление консента
    MESSAGE,    // Консент удален, отображается сообщение
    DELETED,    // Сообщение скрыто
}

interface IProperties {
    tppCode: string
    item: IConsentDetails
    token?: string
    onClick: (tppCode: string) => void
    onDelete: () => void
}

interface IState {
    status: Status
    open: boolean
}

/**
 * Карточка, отображающая информацию о консенте.
 * Отображается список счетов и полномочий.
 * Консент можно отменить (удалить).
 */
export default class CardConsent extends React.Component<IProperties, IState> {

    state: IState = {
        status: Status.EXIST,
        open: false,
    };

    /**
     * Показать диалог отмены консента
     */
    showConfirmation = () =>
    {
        this.setState({ open: true });
    };

    /**
     * Отмена удаления консента
     */
    handleCancel = () =>
    {
        this.setState({ open: false });
    };

    /**
     * Отправка запроса на удаление консента
     */
    deleteConsent = () =>
    {
        this.setState({ status: Status.DELETING, open: false });

        let headers: any = {};
        if (this.props.token)
        {
            headers["Authorization"] = "Bearer " + this.props.token;
        }

        fetch(getContextPath() + "/api/delete-consent/" + this.props.item.consentId, {
                method: "DELETE",
                headers: headers,
            })
            .then(response => response.json())
            .then(response =>
            {
                // todo catch errors
                this.props.onDelete();
                this.setState({ status: Status.MESSAGE, open: false });
            })
    };

    /**
     * Скрыть сообшение об успешном удалении консента
     */
    handleDismiss = () =>
    {
        this.setState({ status: Status.DELETED, open: false });
    };

    render()
    {
        if (this.state.status === Status.DELETED)
            return null;

        if (this.state.status === Status.MESSAGE)
        {
            return (
                <Message onDismiss={this.handleDismiss}>
                    <Message.Header>{<MSGS id="consent_manage_consent_canceled_header"/>}</Message.Header>
                    <p>{<MSGS id="consent_manage_consent_canceled_body"/>}</p>
                </Message>
            );
        }

        let extraContent = new Array<JSX.Element>();
        if (this.state.status === Status.DELETING)
        {
            extraContent.push(
                <Card.Content extra key={0}>
                    <Dimmer active inverted>
                        <Loader inverted>{<MSGS id="consent_manage_consent_process_cancel"/>}</Loader>
                    </Dimmer>
                </Card.Content>
            );
        }
        else
        {
            extraContent.push(
                <Card.Content extra key={0}>
                        <ConsentAccordion itemAccounts={this.props.item.accounts} itemPermissions={this.props.item.permissions}/>
                    </Card.Content>,
                    <Card.Content extra>
                        <Button onClick={this.showConfirmation}
                                type={"button"}
                                fluid
                                basic
                                color='red'>
                            {<MSGS id="consent_manage_consent_button"/>}
                        </Button>
                        <ConfirmDialog tppName={this.props.tppCode}
                                       open={this.state.open}
                                       handleCancel={this.handleCancel}
                                       deleteConsent={this.deleteConsent}/>
                    </Card.Content>
            );
        }

        return (
            <Card fluid>
                <Card.Content>
                    <Card.Header>{this.props.tppCode}</Card.Header>
                    <Card.Meta>{<MSGS id="consent_manage_consent_id" values={{consent_id: this.props.item.consentId}}/>}</Card.Meta>
                    <Card.Description>
                        {<MSGS id="consent_manage_consent_lifetime"/>} {this.props.item.authorizationDate} - {this.props.item.expirationDate}
                    </Card.Description>
                </Card.Content>
                {extraContent}
            </Card>
        );
    }
}

const ConfirmDialog: React.FC<{tppName: string, open: boolean,
    handleCancel: () => void, deleteConsent: () => void}> = ({tppName, open, handleCancel, deleteConsent}) =>
{
    const intl = useIntl();

    let confirmationText = intl.formatHTMLMessage({id: "consent_manage_consent_confirmation_text"},
        {tpp_name: tppName});

    return (
        <Confirm open={open}
                 content={confirmationText}
                 cancelButton={intl.formatHTMLMessage({id: "consent_manage_consent_confirmation_no"})}
                 confirmButton={intl.formatHTMLMessage({id: "consent_manage_consent_confirmation_yes"})}
                 onCancel={handleCancel}
                 onConfirm={deleteConsent} />
    );
};

const ConsentAccordion: React.FC<{itemAccounts: Array<Account>, itemPermissions: Array<string>}> = ({itemAccounts, itemPermissions}) =>
{
    const intl = useIntl();
    let accounts = new Array<JSX.Element>();

    itemAccounts.forEach(account => {
        accounts.push(
            <List.Item key={account.code}>
                <List.Content className={"word-wrap"}>
                    <List.Header>{account.name}</List.Header>
                    {account.code}
                </List.Content>
            </List.Item>
        )
    });

    let accountAccordion: any = {};
    accountAccordion["key"] = "accounts";
    accountAccordion["title"] = intl.formatHTMLMessage({id: "consent_manage_consent_accounts"});
    accountAccordion["content"] = {content: <List divided verticalAlign='middle'>{accounts}</List>};

    let permissions = itemPermissions.map(elem =>
        <List.Item key={elem.toString()}>
            <List.Content>
                <List.Header>
                    {elem}
                </List.Header>
            </List.Content>
        </List.Item>
    );

    let permissionsAccordion: any = {};
    permissionsAccordion["key"] = "permissions";
    permissionsAccordion["title"] = intl.formatHTMLMessage({id: "consent_manage_consent_permissions"});
    permissionsAccordion["content"] = {content: <List divided verticalAlign='middle'>{permissions}</List>};

    let rootAccordion = Array<any>();
    rootAccordion.push(accountAccordion);
    rootAccordion.push(permissionsAccordion);

    return (
        <Accordion panels={rootAccordion} exclusive={false}/>
    );
};