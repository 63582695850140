import React from "react";
import {Divider, Grid, Header, Icon, Segment} from "semantic-ui-react";
import {FormattedHTMLMessage as MSGS} from "react-intl";

interface IProperties {
    spoDetails: {
        creditorName: string
        account?: {
            code: string
            type: string
        }
        frequency: string
        numberOfPayments: number
        currencyOfTransfer?: string
        firstPayment: ICustomPaymentDetails
        recurringPayment?: ICustomPaymentDetails
        lastPayment?: ICustomPaymentDetails
    }
}

interface ICustomPaymentDetails {
    date: string
    currency: string
    amount: number
}

/**
 * Карточка, отображающая информацию о spo, который проходит авторизацию.
 */
export default class CardSpo extends React.Component<IProperties> {

    render()
    {
        let from = new Array<JSX.Element>();
        if (this.props.spoDetails.account)
        {
            from.push(
                <Divider vertical key={0}><Icon name='arrow right' /></Divider>,
                <Grid.Column key={1}>
                    {<MSGS id="welcome_page_payment_consent_from"/>}
                    <Header className={"word-wrap"}>
                        {this.props.spoDetails.account.code}
                    </Header>
                </Grid.Column>
            );
        }

        let numberOfPayments;
        if (this.props.spoDetails.numberOfPayments)
        {
            numberOfPayments = <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_number_of_payments"/>} {this.props.spoDetails.numberOfPayments}</div>;
        }
        else 
        {
            numberOfPayments = <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_open_ended"/>}</div>;
        }

        let currencyOfTransfer;
        if (this.props.spoDetails.currencyOfTransfer)
        {
            currencyOfTransfer = <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_currency_of_transfer"/>} {this.props.spoDetails.currencyOfTransfer}</div>;
        }

        let firstPayment = new Array<JSX.Element>();
        firstPayment.push(
            <div key={"firstPayment"}>
                <div className={"app-text"}>{<MSGS id="welcome_page_spo_first_payment"/>}</div>
                <Segment>
                    <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_date"/>} {this.props.spoDetails.firstPayment.date}</div>
                    <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_amount"/>} {this.props.spoDetails.firstPayment.amount}</div>
                    <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_currency"/>} {this.props.spoDetails.firstPayment.currency}</div>
                </Segment>
            </div>
        )

        let recurringPayment = new Array<JSX.Element>();
        if (this.props.spoDetails.recurringPayment) {
            recurringPayment.push(
                <div key={"recurringPayment"}>
                    <div className={"app-text"}>{<MSGS id="welcome_page_spo_recurring_payment"/>}</div>
                    <Segment>
                        <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_date"/>} {this.props.spoDetails.recurringPayment.date}</div>
                        <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_amount"/>} {this.props.spoDetails.recurringPayment.amount}</div>
                        <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_currency"/>} {this.props.spoDetails.recurringPayment.currency}</div>
                    </Segment>
                </div>
            )
        }

        let lastPayment = new Array<JSX.Element>();
        if (this.props.spoDetails.lastPayment) {
            lastPayment.push(
                <div key={"finalPayment"}>
                    <div className={"app-text"}>{<MSGS id="welcome_page_spo_last_payment"/>}</div>
                    <Segment>
                        <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_date"/>} {this.props.spoDetails.lastPayment.date}</div>
                        <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_amount"/>} {this.props.spoDetails.lastPayment.amount}</div>
                        <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_currency"/>} {this.props.spoDetails.lastPayment.currency}</div>
                    </Segment>
                </div>
            )
        }

        return (
            <div>
                <div className={"app-text"}><MSGS id="welcome_page_spo_payment_consent_first_line"/></div>
                <Segment >
                    <Segment>
                        <div className={"app-subtext"}> {<MSGS id="welcome_page_spo_frequency"/>} {this.props.spoDetails.frequency}</div>
                        {numberOfPayments}
                        {currencyOfTransfer}
                        <Grid columns={2} stackable textAlign='center'>
                            <Grid.Row verticalAlign='middle'>
                                {from}
                                <Grid.Column>
                                    {<MSGS id="welcome_page_payment_consent_to"/>}
                                    <Header>
                                        {this.props.spoDetails.creditorName}
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                    <Segment>
                        {firstPayment}
                        {recurringPayment}
                        {lastPayment}
                    </Segment>

                </Segment>
            </div>
        )
    }
}